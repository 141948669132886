
import Vue from 'vue';

import { newsActions } from '@/store/modules/news/names';
import { mapActions } from 'vuex';

export default Vue.extend({
    name: 'DeleteNews',

    props: {
        deleteDialog: {
            type: Boolean,
            default: null,
            required: true,
        },
        deletePostId: {
            default: null,
            required: true,
        },
        callback: {
            type: Function,
            required: false,
        },
    },

    data() {
        return {
            deleteProcess: false,
            deleteSuccess: false,
        };
    },

    methods: {
        ...mapActions({
            deleteNews: newsActions.deleteNews,
        }),

        async processDeletePost() {
            this.deleteProcess = true;

            await this.deleteNews({
                id: this.deletePostId,
            }).then(() => {
                this.deleteProcess = false;
                this.deleteSuccess = true;

                this.callback();
            });
        },

        closeModal() {
            this.$emit('update:deleteDialog', false);
            this.deleteProcess = false;
            this.deleteSuccess = false;
        },

    },
});
