

// @ts-nocheck

import Vue from 'vue';

import { mapActions, mapState } from 'vuex';

import { Editor, EditorContent, EditorMenuBubble } from 'tiptap';

import LangSelector from '@/components/helper/LangSelect.vue';
import DeleteDialog from './DeleteNews.vue';

import { required, minLength } from 'vuelidate/lib/validators';
import { mdiPencil } from '@mdi/js';
import moment from 'moment';

import {
    Bold,
    Italic,
    Link,
    Underline,
    Placeholder,
} from 'tiptap-extensions';

import { newsActions } from '@/store/modules/news/names';
import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'EditNews',

    validations: {
        post: {
            language: { required },
            title: { required },
            text: { required, minLength: minLength(20) },
            published_date: { required },
        },
    },
    components: {
        DeleteDialog,
        LangSelector,
        EditorContent,
        EditorMenuBubble,
    },
    data() {
        return {
            linkUrl: null,
            linkMenuIsActive: false,

            deleteDialog: false,

            newsId: this.$route.params.id,

            process: false,
            alertSuccess: false,
            alertError: false,

            image: '' as string | null,
            imageFile: '',
            imageType: null,
            post: {
                id: 0,
                title: '',
                text: '',
                cover_url: '',
                language: '',
                is_published: false,
                tags: [] as string[],
                published_date: '',
            },
            location: {
                landing: false,
                account: false,
            },

            isDatepickerShown: false,
            todayDate: moment().format('YYYY-MM-DD'),
            dateIcon: mdiPencil,

            keepInBounds: true,
            editor: new Editor({
                extensions: [
                    new Link(),
                    new Bold(),
                    new Italic(),
                    new Underline(),
                    new Placeholder({
                        emptyEditorClass: 'is-editor-empty',
                        emptyNodeClass: 'is-empty',
                        emptyNodeText: 'text…',
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true,
                    }),
                ],
                content: '',
                onUpdate: ({ getHTML }) => {
                    // @ts-ignore
                    this.post.text = getHTML();
                },
            }),
        };
    },
    computed: {
        ...mapState<ComposedState>({
            news: (state: ComposedState) => state.news.news,
        }),
        titleError() {
            const errors: string[] = [];
            if (!this.$v.post.title?.$dirty) return errors;
            if (!this.$v.post.title.required) {
                errors.push('Title is required.');
            }
            return errors;
        },
        textError() {
            const errors: string[] = [];
            if (!this.$v.post.text?.$dirty) return errors;
            if (!this.$v.post.text.required) {
                errors.push('Text is required.');
            }
            if (!this.$v.post.text.minLength) {
                errors.push(
                    'In the text field, the minimum number of characters is 20!',
                );
            }
            return errors;
        },
        languageError() {
            const errors: string[] = [];
            if (!this.$v.post.language?.$dirty) return errors;
            if (!this.$v.post.language.required) {
                errors.push('Language is required.');
            }
            return errors;
        },
    },
    watch: {
        location: {
            deep: true,
            handler(newval) {
                // @ts-ignore
                this.post.tags = Object.keys(newval).map((k) => {
                    if (newval[k]) {
                        return k;
                    }
                }).filter((t) => t);
            },
        },
    },
    async mounted() {
        // @ts-ignore
        const currentNews = this.news.find((i) => {
            if (i.id === this.newsId) {
                return i;
            }
        });

        if (!currentNews) {
            this.$router.replace({ name: 'news-list' });
        } else {
            const {
                id,
                title,
                text,
                cover_url,
                language,
                is_published,
                tags,
                published_date,
                
                // @ts-ignore
            } = this.news.filter((item) => {
                if (item.id === this.newsId) {
                    return item;
                }
            })[0];
        }

        const newsToEdit = this.news.find((news) => news.id === this.newsId);

        if (!newsToEdit) {
            return this.$router.replace({ name: 'news-list' });
        }

        this.image = newsToEdit.cover_url;

        this.post = {
            id: newsToEdit.id,
            title: newsToEdit.title,
            text: newsToEdit.text,
            cover_url: newsToEdit.cover_url,
            language: newsToEdit.language,
            is_published: newsToEdit.is_published,
            published_date: newsToEdit.published_date,
            tags: [],
        };

        if (newsToEdit.tags) {
            this.post.tags = [...newsToEdit.tags];

            this.post.tags.forEach((tag) => {
                this.location[tag] = true;
            });
        }
        
        this.editor.setContent(this.post.text);
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    methods: {
        ...mapActions({
            fetchNews: newsActions.fetchNews,
            updateNews: newsActions.updateNews,
        }),

        showLinkMenu(attrs) {
            this.linkUrl = attrs.href;
            this.linkMenuIsActive = true;
            this.$nextTick(() => {
                (this.$refs.linkInput as HTMLInputElement).focus();
            });
        },
        hideLinkMenu() {
            this.linkUrl = null;
            this.linkMenuIsActive = false;
        },
        setLinkUrl(command, url) {
            command({ href: url });
            this.hideLinkMenu();
        },
        onFileChange(file) {
            if (!file) return;
            this.imageFile = file;
            this.imageType = file.type;
            this.createImage(file);
        },
        createImage(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                // @ts-ignore
                this.image = e.target?.result;
            };
            reader.readAsDataURL(file);
        },
        removeImage() {
            this.image = '';
            this.process = false;
        },
        updateAlerts() {
            this.alertSuccess = false;
            this.alertError = false;
        },
        async submit() {
            this.$v.post.$touch();

            if (!this.$v.post.$error) {
                this.process = true;

                const formData = new FormData();

                Object.keys(this.post).forEach((key) => {
                    // @ts-ignore
                    formData.append([key], this.post[key]);
                });
                if (this.imageFile) {
                    formData.delete('cover_url');
                    formData.append('cover_url', this.imageFile);
                }

                await this.updateNews({
                    params: formData,
                    id: this.newsId,
                }).then(() => {
                    this.alertSuccess = true;
                    this.process = false;

                    setTimeout(() => {
                        this.updateAlerts();
                    }, 3000);
                });
                await this.$router.replace({ name: 'news-list' });
            }
        },
        callDeleteDialog() {
            this.deleteDialog = true;
        },
        async afterDeleteNews() {
            await this.$router.replace({ name: 'news-list' });
        },
    },
});
